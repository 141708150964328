import { db, timestamp } from '@/config/firebase'
import fileUpload from '@/services/fileUploadService'
import getLocation from '@/services/getLocation'
import { logging } from '@/services/logging.js'

function logAndResolve (resolve, message, dataToReturn = null) {
  resolve(dataToReturn)
}

function logAndReject (reject, err, message, dataToReturn = null) {
  reject(dataToReturn)
}

export default {
  loadCargo ({ commit }, cargo) {
    // Commit a cargo loaded by others methods
    // TODO add operations logs
    commit('changeCargos', cargo)
  },

  getAndOpenCargo ({ commit, rootState, dispatch }, payload) {
    // From a form with truck_id, operation and km
    // Search for a list of cargos based on companyId and truck ID
    // If has cargos... upload km foto and update new km for selected truck
    // Return cargos object with error and cargos list

    commit('Shared/setLoadingMessage', 'Buscando carga...', { root: true })
    commit('Shared/clearError', null, { root: true })

    const companyId = rootState.User.profile.company_id || false
    const userId = rootState.User.profile.id || false
    const truckId = payload.truck_id || false
    const kminit = parseFloat(payload.initialKm) || false
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
    let cargo = rootState.Cargos.cargo || null

    let validateForm = new Promise((resolve, reject) => {
      if (!truckId) reject(new Error('Truck ID cant be empty'))
      if (!companyId) reject(new Error('Company ID cant be empty'))
      if (!userId) reject(new Error('User ID cant be empty'))
      if (!kminit) reject(new Error('km cant be empty'))
      resolve()
    })

    // Dados para update no banco
    let cargoUpdate = {
      started_by: userId,
      started_at: timestamp,
      started_km: kminit,
      helpers_ids: payload.helpers_ids || [],
      helpers_names: payload.helpers_names || [],
      started_geo: null,
      cargo_status: null
    }

    // Check if exist a valid cargo
    return validateForm
      .then(() => getLocation())
      .then(location => {
        cargoUpdate.started_geo = location
      })
      .then(() => {
        if (!cargo) {
          return dispatch('loadCargoByTruckId', truckId)
        } else {
          return cargo
        }
      })
      .then(cargo => {
        if (!cargo) throw new Error('Lista de cargas não encontradas - E2')
        cargo = JSON.parse(JSON.stringify(cargo))

        const docID = `${cargo.branch_id}${cargo.cargo_id}`

        // Dados para Update do database
        cargoUpdate.cargo_status = cargo.cargo_status === 'A' ? 'D' : cargo.cargo_status

        // Dados para update do state
        cargo.started_by = userId
        cargo.started_at = { seconds: Math.round(new Date().getTime() / 1000) }
        cargo.started_km = kminit
        cargo.started_geo = cargoUpdate.started_geo
        cargo.cargo_status = cargoUpdate.cargo_status

        // Update Initial km in Firebase and State
        dispatch('Trucks/saveAndSetTruckNewKm', kminit, { root: true })

        // Log
        logging(`Cargo ${docID} Opened`, 'Cargos', 'Open Cargo')

        // Database Update
        cargoRef
          .doc(docID)
          .update(cargoUpdate)

        // Upload photo and update database url
        if (payload.foto) {
          const metadata = {
            cargo_id: cargo.cargo_id,
            collection_name: 'cargos',
            branch_id: cargo.branch_id,
            doc_id: cargo.cargo_id,
            doc_field: `started_km_photo_url`,
            image_tag: 'started_km_photo',
            doc_ref: cargoRef.doc(docID)
          }

          fileUpload.upload(payload.foto, metadata).then(base64Docid => {
            cargoRef.doc(docID).update({
              started_km_photo: base64Docid
            })
          })
        }

        commit('changeCargos', cargo)
        commit('Shared/setLoadingMessage', '', { root: true })
        return { error: false, cargos: cargo }
      })
      .catch(err => {
        commit('Shared/setError', err.message, { root: true })
        return { error: err, cargos: null }
      })
  },

  getOpenedCargos ({ commit, rootState }) {
    const userId = rootState.User.profile.id
    const companyId = rootState.User.profile.company_id
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')

    return cargoRef
      .where('started_by', '==', userId)
      .where('cargo_status', '==', 'D')
      .limit(1)
      .get()
      .then(querySnapshot => (!querySnapshot.empty ? querySnapshot.docs[querySnapshot.docs.length - 1].data() : false))
      .catch(err => {
        commit('Shared/setError', err.message, { root: true })
      })
  },

  loadCargoByTruckId ({ commit, rootState }, truckId) {
    commit('Shared/setLoadingMessage', `Buscando carga para ${truckId}...`, { root: true })

    let cargoRef = db
      .collection('companies')
      .doc(rootState.User.profile.company_id)
      .collection('cargos')

    return cargoRef
      .where('truck_id', '==', truckId)
      .where('cargo_status', '==', 'A')
      .limit(1)
      .get()
      .then(querySnapshot => {
        commit('Shared/setLoadingMessage', '', { root: true })
        if (!querySnapshot.empty) {
          const cargo = querySnapshot.docs[querySnapshot.docs.length - 1].data()
          commit('changeCargos', cargo)
          return cargo
        } else {
          commit('clearCargos')
          return false
        }
      })
      .catch(err => {
        commit('Shared/setLoadingMessage', '', { root: true })
        commit('Shared/setError', err.message, { root: true })
        return false
      })
  },

  cancellationConfirm ({ commit, rootState, state }, payload) {
    commit('Shared/setLoadingMessage', 'Cancelando entrega...', { root: true })
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    let companyId = rootState.User.profile.company_id
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    let cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)

    let customerIndex = Object.values(cargo.customers).findIndex(customer => {
      return customer.customer + customer.address1 + customer.sequenceofdelivery === payload.customer.customer + payload.customer.address1 + payload.customer.sequenceofdelivery
    })

    // Upload photo and update database url
    if (payload.photo) {
      const metadata = {
        collection_name: 'cargos',
        branch_id: cargo.branch_id,
        cargo_id: cargo.cargo_id,
        doc_id: docID,
        doc_field: `customers.${customerIndex}.canceled_photo_url`,
        image_tag: 'customer_cancellation',
        doc_ref: cargoRef
      }

      fileUpload.upload(payload.photo, metadata).then(base64Docid => {
        let customerPhotoUpdate = {}
        cargo.customers[customerIndex].canceled_photo = base64Docid
        customerPhotoUpdate[`customers.${customerIndex}.canceled_photo`] = base64Docid

        cargoRef.update(customerPhotoUpdate) // .then(() => { console.log(`Doc ${docID}/customers/${payload.index}/canceled_photo successfully updated`) })

        commit('changeCargos', cargo)
      })
    }

    // Dados para update no banco
    let customerUpdate = {}
    customerUpdate[`customers.${customerIndex}.status`] = 'canceled'
    customerUpdate[`customers.${customerIndex}.canceled_at`] = timestamp
    customerUpdate[`customers.${customerIndex}.canceled_reason`] = payload.reason
    customerUpdate[`customers.${customerIndex}.canceled_notice`] = payload.notice

    // Dados para update do state
    cargo.customers[customerIndex].status = 'canceled'
    cargo.customers[customerIndex].canceled_at = { seconds: Math.round(new Date().getTime() / 1000) }
    cargo.customers[customerIndex].canceled_reason = payload.reason
    cargo.customers[customerIndex].canceled_notice = payload.notice
    cargo.customers[customerIndex].has_invoice_canceled = true

    // Redefinindo status dos invoices
    Object.values(cargo.customers[customerIndex].invoices).map(invoiceInPromise => {
      let invoiceIndex = Object.values(cargo.customers[customerIndex].invoices).findIndex(findInvoice => findInvoice.invoice === invoiceInPromise.invoice)
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.status`] = 'canceled'
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_at`] = { seconds: Math.round((new Date()).getTime() / 1000) }
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_photo`] = null
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_notice`] = `${payload.notice} | Entrega`
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_reason`] = payload.reason
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_desc`] = 'Cancelamento automático | Documento cancelado a nível de cliente'
      customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.receipt_photo`] = null
      cargo.customers[customerIndex].invoices[invoiceIndex].status = 'canceled'
      cargo.customers[customerIndex].invoices[invoiceIndex].cancel_at = { seconds: Math.round((new Date()).getTime() / 1000) }
      cargo.customers[customerIndex].invoices[invoiceIndex].cancel_photo = null
      cargo.customers[customerIndex].invoices[invoiceIndex].cancel_notice = `${payload.notice} | Entrega`
      cargo.customers[customerIndex].invoices[invoiceIndex].cancel_reason = payload.reason
      cargo.customers[customerIndex].invoices[invoiceIndex].cancel_desc = 'Cancelamento automático | Documento cancelado a nível de cliente'
      cargo.customers[customerIndex].invoices[invoiceIndex].receipt_photo = null
    })

    // Update do state
    commit('changeCargos', cargo)
    commit('Shared/setLoadingMessage', '', { root: true })

    // Update do banco
    return cargoRef.update(customerUpdate) // .then(() => console.log(`Doc ${cargo.cargo_id}/customers/${payload.index} successfully updated`))
  },

  deliveryConfirm ({ commit, rootState, dispatch, state }, payload) {
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    const companyId = rootState.User.profile.company_id
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)
    const customerIndex = Object.values(cargo.customers).findIndex(customer => customer.customer + customer.address1 + customer.sequenceofdelivery === payload.deliveryCustomer.customer + payload.deliveryCustomer.address1 + payload.deliveryCustomer.sequenceofdelivery)
    const kmTruck = parseFloat(payload.kmTruck) || 0

    getLocation().then(location => {
      // Array for tasks promises
      let deliveryConfirmTasks = []

      // save/update Delivery Info to Database and to state
      deliveryConfirmTasks[0] = new Promise((resolve, reject) => {
        // Dados para update no banco
        let customerUpdate = {}
        customerUpdate[`customers.${customerIndex}.status`] = 'delivered'
        customerUpdate[`customers.${customerIndex}.delivered_at`] = timestamp
        customerUpdate[`customers.${customerIndex}.delivered_geo`] = location
        customerUpdate[`customers.${customerIndex}.delivered_who`] = payload.whoReceived
        customerUpdate[`customers.${customerIndex}.delivered_doc`] = payload.documentReceived
        customerUpdate[`customers.${customerIndex}.delivered_info`] = payload.deliveryNotice
        customerUpdate[`customers.${customerIndex}.delivered_km`] = kmTruck

        // Dados para update do state
        cargo.customers[customerIndex].status = 'delivered'
        cargo.customers[customerIndex].delivered_at = { seconds: Math.round(new Date().getTime() / 1000) }
        cargo.customers[customerIndex].delivered_geo = location
        cargo.customers[customerIndex].delivered_who = payload.whoReceived
        cargo.customers[customerIndex].delivered_doc = payload.documentReceived
        cargo.customers[customerIndex].delivered_info = payload.deliveryNotice
        cargo.customers[customerIndex].delivered_km = kmTruck

        // Se foi entregue, mas todas as notas foram canceladas, muda status do customer
        let totalInvoices = Object.values(payload.deliveryCustomer.invoices).length
        let totalCanceledInvoices = 0
        Object.values(payload.deliveryCustomer.invoices).forEach(invoice => {
          if (invoice.status === 'canceled') totalCanceledInvoices++
        })

        if (totalInvoices === totalCanceledInvoices) {
          customerUpdate[`customers.${customerIndex}.status`] = 'canceled'
          customerUpdate[`customers.${customerIndex}.canceled_at`] = timestamp
          customerUpdate[`customers.${customerIndex}.canceled_reason`] = 'system'
          customerUpdate[`customers.${customerIndex}.canceled_notice`] = 'Todas as notas foram individualmente canceladas'

          // Dados para update do state
          cargo.customers[customerIndex].status = 'canceled'
          cargo.customers[customerIndex].canceled_at = { seconds: Math.round(new Date().getTime() / 1000) }
          cargo.customers[customerIndex].canceled_reason = 'system'
          cargo.customers[customerIndex].canceled_notice = 'Todas as notas foram individualmente canceladas'
        }

        // updateCargo state
        commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

        let subTasks = []
        // updateCargo database
        subTasks[0] = cargoRef.update(customerUpdate) // .then(() => console.log(`deliveryConfirmTasks[0] Doc cargos/${docID}/customers/${customerIndex} successfully updated`))

        // updateTruck
        subTasks[1] = dispatch('Trucks/saveAndSetTruckNewKm', kmTruck, { root: true })

        Promise.all(subTasks)
          .then(() => logAndResolve(resolve, `deliveryConfirmTasks[0] finish`))
          .catch(err => logAndReject(reject, err, `deliveryConfirmTasks[0] error`, err))
      })

      // Se existir foto do kmtruk, faz upload e grava no banco
      deliveryConfirmTasks[1] = new Promise((resolve, reject) => {
        if (payload.deliveryCustomer.delivered_km_photo != null) {
          const metadata = {
            collection_name: 'cargos',
            branch_id: cargo.branch_id,
            cargo_id: cargo.cargo_id,
            doc_id: docID,
            doc_field: `customers.${customerIndex}.delivered_km_photo_url`,
            image_tag: 'delivered_km_photo',
            doc_ref: cargoRef
          }

          let uploadTask = fileUpload.upload(payload.deliveryCustomer.delivered_km_photo, metadata)

          uploadTask.then(imageDocId => {
            // Dados para update no banco
            let customerUpdate = {}
            customerUpdate[`customers.${customerIndex}.delivered_km_photo`] = imageDocId

            // Dados para update do state
            cargo.customers[customerIndex].delivered_km_photo = imageDocId

            // updateCargo state
            commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

            // updateCargo database
            cargoRef
              .update(customerUpdate)
              .then(result => {
                // console.log(`Doc cargos/${docID}/customers/${customerIndex}/delivered_km_photo successfully updated`)
                logAndResolve(resolve, `deliveryConfirmTasks[1] finish`, imageDocId)
              })
              .catch(err => logAndReject(reject, err, `deliveryConfirmTasks[1] error`, err))
          })
        } else {
          resolve(null)
        }
      })

      // Array de promises complexo com processamento de cada invoice da carga
      deliveryConfirmTasks[2] = new Promise((resolve, reject) => {
        // Para cada invoice, faz processamento das fotos e dados
        let invoiceTasks = Object.values(payload.deliveryCustomer.invoices).map(invoiceInPromise => {
          return new Promise((resolve, reject) => {
            // task Variable with for sub tasks promises and index to locate de invoice
            let invoiceIndex = Object.values(cargo.customers[customerIndex].invoices).findIndex(findInvoice => findInvoice.invoice === invoiceInPromise.invoice)
            let invoicesSubTasks = []

            if (invoiceInPromise.status !== 'canceled') {
              cargo.customers[customerIndex].invoices[invoiceIndex].status = 'delivered'
              cargo.customers[customerIndex].invoices[invoiceIndex].delivered_at = { seconds: Math.round(new Date().getTime() / 1000) }
              commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

              let customerUpdate = {}
              customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.status`] = 'delivered'
              customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.delivered_at`] = timestamp

              // updateCargo database
              cargoRef
                .update(customerUpdate)
                .then(() => logAndResolve(resolve, `invoicesSubTasks root Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex}`))
                .catch(err => logAndReject(reject, err, `invoicesSubTasks root error ${invoiceInPromise.invoice}`, err))
            }

            // Se existir receipt photo no invoice
            invoicesSubTasks[0] = new Promise((resolve, reject) => {
              if (invoiceInPromise.receipt_photo != null) {
                const metadata = {
                  collection_name: 'cargos',
                  branch_id: cargo.branch_id,
                  cargo_id: cargo.cargo_id,
                  doc_id: docID,
                  doc_field: `customers.${customerIndex}.invoices.${invoiceIndex}.receipt_photo_url`,
                  doc_invoice: invoiceInPromise.invoice,
                  image_tag: 'receipt_photo',
                  doc_ref: cargoRef
                }

                // Save image in base64 to firestore and state when online
                fileUpload.upload(invoiceInPromise.receipt_photo, metadata).then(imageDocId => {
                  // Dados para update no banco
                  let customerUpdate = {}
                  customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.receipt_photo`] = imageDocId

                  // Dados para update do state
                  cargo.customers[customerIndex].invoices[invoiceIndex].receipt_photo = imageDocId

                  // updateCargo state
                  commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                  // updateCargo database
                  cargoRef
                    .update(customerUpdate)
                    .then(() => logAndResolve(resolve, `invoicesSubTasks[0] Doc cargos/${docID}/customers/${customerIndex}/invoices/${invoiceIndex} receipt_photo successfully updated`, imageDocId))
                    .catch(err => logAndReject(reject, err, `invoicesSubTasks[0] receipt_photo error ${invoiceInPromise.invoice}`, err))
                })
              } else {
                resolve(null)
              }
            })

            // Se existir ressalva photo no invoice
            invoicesSubTasks[1] = new Promise((resolve, reject) => {
              if (invoiceInPromise.safeguard_photo != null) {
                const metadata = {
                  collection_name: 'cargos',
                  branch_id: cargo.branch_id,
                  cargo_id: cargo.cargo_id,
                  doc_id: docID,
                  doc_field: `customers.${customerIndex}.invoices.${invoiceIndex}.safeguard_photo_url`,
                  doc_invoice: invoiceInPromise.invoice,
                  image_tag: 'safeguard_photo',
                  doc_ref: cargoRef
                }

                // Save image in base64 to firestore when online
                fileUpload.upload(invoiceInPromise.safeguard_photo, metadata).then(imageDocId => {
                  // Dados para update no banco
                  let customerUpdate = {}
                  customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.safeguard_photo`] = imageDocId

                  // Dados para update do state
                  cargo.customers[customerIndex].invoices[invoiceIndex].safeguard_photo = imageDocId

                  // updateCargo state
                  commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                  // updateCargo database
                  cargoRef
                    .update(customerUpdate)
                    .then(() => logAndResolve(resolve, `invoicesSubTasks[1] Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex} safeguard_photo successfully updated`, imageDocId))
                    .catch(err => logAndReject(reject, err, `invoicesSubTasks[1] safeguard_photo error ${invoiceInPromise.invoice}`, err))
                })
              } else {
                resolve(null)
              }
            })

            // Se existir invoice cancelado
            invoicesSubTasks[2] = new Promise((resolve, reject) => {
              if (invoiceInPromise.status === 'canceled') {
                // update do state mesmo offline
                cargo.customers[customerIndex].has_invoice_canceled = true
                cargo.customers[customerIndex].invoices[invoiceIndex].status = invoiceInPromise.status
                cargo.customers[customerIndex].invoices[invoiceIndex].cancel_at = { seconds: Math.round(new Date().getTime() / 1000) }

                // updateCargo state
                commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                // Dados para update no banco
                let customerUpdate = {}
                customerUpdate[`customers.${customerIndex}.has_invoice_canceled`] = true
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.status`] = invoiceInPromise.status
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_at`] = timestamp
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_notice`] = invoiceInPromise.cancel_notice
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_reason`] = invoiceInPromise.cancel_reason
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_desc`] = invoiceInPromise.cancel_desc

                if (invoiceInPromise.cancel_photo != null) {
                  const metadata = {
                    collection_name: 'cargos',
                    branch_id: cargo.branch_id,
                    cargo_id: cargo.cargo_id,
                    doc_id: docID,
                    doc_field: `customers.${customerIndex}.invoices.${invoiceIndex}.cancel_photo_url`,
                    doc_invoice: invoiceInPromise.invoice,
                    image_tag: 'cancel_photo',
                    doc_ref: cargoRef
                  }

                  // Save image in base64 to firestore when online
                  fileUpload.upload(invoiceInPromise.cancel_photo, metadata).then(imageDocId => {
                    // Dados para update no banco
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_photo`] = imageDocId

                    // Dados para update do state
                    cargo.customers[customerIndex].invoices[invoiceIndex].cancel_photo = imageDocId

                    // updateCargo state
                    commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                    // updateCargo database
                    cargoRef
                      .update(customerUpdate)
                      .then(() => logAndResolve(resolve, `invoicesSubTasks[1] Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex} cancel_photo successfully updated`, imageDocId))
                      .catch(err => logAndReject(reject, err, `invoicesSubTasks[1] cancel_photo error ${invoiceInPromise.invoice}`, err))
                  })
                } else {
                  // updateCargo database
                  cargoRef
                    .update(customerUpdate)
                    .then(() => logAndResolve(resolve, `invoicesSubTasks[1] Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex}`))
                    .catch(err => logAndReject(reject, err, `invoicesSubTasks[1] cancel_photo error ${invoiceInPromise.invoice}`, err))
                }
              } else {
                resolve(null)
              }
            })

            // Verifica se para cada item da nota, exite parcial, se existir grava imagem e dados no banco e state
            invoicesSubTasks[3] = new Promise((resolve, reject) => {
              let invoicesItemsTasks = Object.values(invoiceInPromise.items).map((item, index) => {
                return new Promise((resolve, reject) => {
                  if (item.partial) {
                    const metadata = {
                      collection_name: 'cargos',
                      branch_id: cargo.branch_id,
                      cargo_id: cargo.cargo_id,
                      doc_id: docID,
                      doc_field: `customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial_photo_url`,
                      doc_invoice: invoiceInPromise.invoice,
                      image_tag: 'partial_photo',
                      doc_ref: cargoRef
                    }

                    // update do state mesmo offline
                    cargo.customers[customerIndex].has_invoice_partial = true
                    cargo.customers[customerIndex].invoices[invoiceIndex].status = 'with_partial'
                    cargo.customers[customerIndex].invoices[invoiceIndex].partial_at = { seconds: Math.round(new Date().getTime() / 1000) }

                    // updateCargo state
                    commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                    // Dados para update no banco
                    let customerUpdate = {}
                    customerUpdate[`customers.${customerIndex}.has_invoice_partial`] = true
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.status`] = 'with_partial'
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.partial_at`] = timestamp
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial`] = true
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial_quantity`] = item.partial_quantity
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial_reason`] = item.partial_reason
                    customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial_desc`] = item.partial_desc

                    // Save image in base64 to firestore if image exist
                    if (item.partial_photo) {
                      fileUpload.upload(item.partial_photo, metadata).then(imageDocId => {
                        customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.items.${index}.partial_photo`] = imageDocId

                        // after updte photo... updateCargo database
                        cargoRef.update(customerUpdate).then(() => logAndResolve(resolve, `Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex}/item/${index} partial successfully updated`, imageDocId))
                      })
                      // If not images, just save to database
                    } else {
                      // just updateCargo database
                      cargoRef.update(customerUpdate).then(() => logAndResolve(resolve, `Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex}/item/${index} partial successfully updated`))
                    }
                  } else {
                    resolve(null)
                  }
                })
              })

              Promise.all(invoicesItemsTasks)
                .then(result => logAndResolve(resolve, `invoicesItemsTasks promises finish -> ${invoiceInPromise.invoice}`))
                .catch(err => logAndReject(reject, err, `invoicesItemsTasks error`, err))
            })

            // Se existir pagamento editado
            invoicesSubTasks[4] = new Promise((resolve, reject) => {
              if (invoiceInPromise.paymentEdited) {
                // Dados para update no banco
                let customerUpdate = {}
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.paymentEdited`] = invoiceInPromise.paymentEdited

                // Dados para update do state
                cargo.customers[customerIndex].invoices[invoiceIndex].paymentEdited = invoiceInPromise.paymentEdited

                // updateCargo state
                commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                // updateCargo database
                cargoRef
                  .update(customerUpdate)
                  .then(() => logAndResolve(resolve, `invoicesSubTasks[4] Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex} paymentEdited successfully updated`, invoiceInPromise.paymentEdited))
                  .catch(err => logAndReject(reject, err, `invoicesSubTasks[4] paymentEdited error ${invoiceInPromise.invoice}`, err))
              } else if (invoiceInPromise.nsuNumber) {
                // Dados para update no banco
                let customerUpdate = {}
                customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.payment.${0}.nsuNumber`] = invoiceInPromise.nsuNumber

                // Dados para update do state
                cargo.customers[customerIndex].invoices[invoiceIndex].payment[0].nsuNumber = invoiceInPromise.nsuNumber

                // updateCargo state
                commit('changeCargos', JSON.parse(JSON.stringify(cargo)))

                // updateCargo database
                cargoRef
                  .update(customerUpdate)
                  .then(() => logAndResolve(resolve, `invoicesSubTasks[4] Doc cargos/${docID}/customers/${customerIndex}/invoice/${invoiceIndex} nsuNumber successfully updated`, invoiceInPromise.paymentEdited))
                  .catch(err => logAndReject(reject, err, `invoicesSubTasks[4] nsuNumber error ${invoiceInPromise.invoice}`, err))
              } else {
                resolve(null)
              }
            })

            Promise.all(invoicesSubTasks)
              .then(result => logAndResolve(resolve, `invoicesSubTasks finish -> ${invoiceInPromise.invoice}`))
              .catch(err => logAndReject(reject, err, `invoicesSubTasks error ${invoiceInPromise.invoice}`, err))
          })
        })

        Promise.all(invoiceTasks)
          .then(result => logAndResolve(resolve, `deliveryConfirmTasks[2] if item partial invoiceTasks finish`))
          .catch(err => logAndReject(reject, err, `deliveryConfirmTasks[2] if item partial invoiceTasks error`, err))
      })

      return Promise.all(deliveryConfirmTasks)
        .then(result => {
          logging(`Customer finished, deliver finished`, 'Cargos', 'Close Cargo')
        })
    }) // fim do getLocation()
  },

  setDeliverCustomer ({ commit }, payload) {
    if (payload) logging(`Deliver customer set to ${payload.customer || null}`, 'Cargos', 'Set Deliver')
    commit('changeDeliverCustomer', payload || {})
  },

  setDeliveringCustomer ({ commit, rootState, state }, customer) {
    const companyId = rootState.User.profile.company_id
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)
    const customerIndex = Object.values(state.cargo.customers).findIndex(customerArr => customerArr.customer + customerArr.address1 + customerArr.sequenceofdelivery === customer.customer + customer.address1 + customer.sequenceofdelivery)

    return new Promise((resolve, reject) => {
      if (customerIndex > -1) {
        commit('Shared/setLoadingMessage', 'Iniciando entrega...', { root: true })
        getLocation().then(location => {
          // Dados para update no banco
          let customerUpdate = {}
          customerUpdate[`customers.${customerIndex}.status`] = 'delivering'
          customerUpdate[`customers.${customerIndex}.delivering_at`] = timestamp
          customerUpdate[`customers.${customerIndex}.delivering_geo`] = location

          // Dados para update do state
          cargo.customers[customerIndex].status = 'delivering'
          cargo.customers[customerIndex].delivering_at = { seconds: Math.round(new Date().getTime() / 1000) }
          cargo.customers[customerIndex].delivering_geo = location

          // updateCargo state
          commit('Shared/setLoadingMessage', '', { root: true })
          commit('changeCargos', cargo)
          commit('changeDeliverCustomer', cargo.customers[customerIndex])

          // Log
          logging(`Doc cargos/${docID}/customers/${customerIndex} successfully updated with status delivering`, 'Cargos', 'Set Delivering')

          // update Database
          cargoRef.update(customerUpdate) // .then(() => console.log(`Doc cargos/${docID}/customers/${customerIndex} successfully updated`))

          // resolve promise
          resolve()
        })
      } else {
        return reject(new Error('Invalid Customer'))
      }
    })
  },

  setDeliveringCheckinCustomer ({ commit, rootState, state }, customer) {
    const companyId = rootState.User.profile.company_id
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)
    const customerIndex = Object.values(state.cargo.customers).findIndex(customerArr => customerArr.customer + customerArr.address1 + customerArr.sequenceofdelivery === customer.customer + customer.address1 + customer.sequenceofdelivery)

    return new Promise((resolve, reject) => {
      if (customerIndex > -1) {
        commit('Shared/setLoadingMessage', 'Realizando checkin...', { root: true })
        getLocation().then(location => {
          // Dados para update no banco
          let customerUpdate = {}
          customerUpdate[`customers.${customerIndex}.status`] = 'delivering_checkin'
          customerUpdate[`customers.${customerIndex}.delivering_checkin_at`] = timestamp
          customerUpdate[`customers.${customerIndex}.delivering_checkin_geo`] = location

          // Dados para update do state
          cargo.customers[customerIndex].status = 'delivering_checkin'
          cargo.customers[customerIndex].delivering_checkin_at = { seconds: Math.round(new Date().getTime() / 1000) }
          cargo.customers[customerIndex].delivering_checkin_geo = location

          // updateCargo state
          commit('Shared/setLoadingMessage', '', { root: true })
          commit('changeCargos', cargo)
          commit('changeDeliverCustomer', cargo.customers[customerIndex])

          // Log
          logging(`Doc cargos/${docID}/customers/${customerIndex} successfully checked in`, 'Cargos', 'Checkin')

          // update Database
          cargoRef.update(customerUpdate) // .then(() => console.log(`Doc cargos/${docID}/customers/${customerIndex} successfully updated`))

          // resolve promise
          resolve()
        })
      } else {
        return reject(new Error('Invalid Customer'))
      }
    })
  },

  setAvaliableAfterCanceledCustomer ({ commit, rootState, state }, customer) {
    const companyId = rootState.User.profile.company_id
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    const cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)
    const customerIndex = Object.values(state.cargo.customers).findIndex(customerArr => customerArr.customer + customerArr.address1 + customerArr.sequenceofdelivery === customer.customer + customer.address1 + customer.sequenceofdelivery)

    return new Promise((resolve, reject) => {
      if (customerIndex > -1) {
        commit('Shared/setLoadingMessage', 'Aplicando alteração...', { root: true })
        getLocation().then(location => {
          // Dados para update no banco
          let customerUpdate = {}
          customerUpdate[`customers.${customerIndex}.status`] = 'reavailable'
          customerUpdate[`customers.${customerIndex}.reavailable_at`] = timestamp
          customerUpdate[`customers.${customerIndex}.reavailable_geo`] = location

          // Dados para update do state
          cargo.customers[customerIndex].status = 'reavailable'
          cargo.customers[customerIndex].reavailable_at = { seconds: Math.round(new Date().getTime() / 1000) }
          cargo.customers[customerIndex].reavailable_geo = location

          // Redefinindo status dos invoices
          // this.mixDeliverCustomerInvoice({ invoiceNumber, deletePartial: true, deleteCancel: true, data: {} })
          Object.values(cargo.customers[customerIndex].invoices).map(invoiceInPromise => {
            let invoiceIndex = Object.values(cargo.customers[customerIndex].invoices).findIndex(findInvoice => findInvoice.invoice === invoiceInPromise.invoice)
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.status`] = 'reavailable'
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.reavailable_at`] = { seconds: Math.round(new Date().getTime() / 1000) }
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_at`] = null
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_photo`] = null
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_notice`] = 'Entrega reiniciada'
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_reason`] = null
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.cancel_desc`] = 'Documento redisponibilizado após entrega cancelada'
            customerUpdate[`customers.${customerIndex}.invoices.${invoiceIndex}.receipt_photo`] = null
            cargo.customers[customerIndex].invoices[invoiceIndex].status = 'reavailable'
            cargo.customers[customerIndex].invoices[invoiceIndex].reavailable_at = { seconds: Math.round(new Date().getTime() / 1000) }
            cargo.customers[customerIndex].invoices[invoiceIndex].cancel_at = null
            cargo.customers[customerIndex].invoices[invoiceIndex].cancel_photo = null
            cargo.customers[customerIndex].invoices[invoiceIndex].cancel_notice = 'Entrega reiniciada'
            cargo.customers[customerIndex].invoices[invoiceIndex].cancel_reason = null
            cargo.customers[customerIndex].invoices[invoiceIndex].cancel_desc = 'Documento redisponibilizado após entrega cancelada'
            cargo.customers[customerIndex].invoices[invoiceIndex].receipt_photo = null
          })

          // updateCargo state
          commit('Shared/setLoadingMessage', '', { root: true })
          commit('changeCargos', cargo)

          // Log
          logging(`Doc cargos/${docID}/customers/${customerIndex} successfully reavailable in`, 'Cargos', 'Checkin')

          // update Database
          cargoRef.update(customerUpdate) // .then(() => console.log(`Doc cargos/${docID}/customers/${customerIndex} successfully updated`))

          // resolve promise
          resolve()
        })
      } else {
        return reject(new Error('Invalid Customer'))
      }
    })
  },

  closeCargoValidade ({ state }) {
    const customers = Object.values(state.cargo.customers)
    const customerWithoutStatus = customers.filter(customer => !customer.status)
    return customerWithoutStatus.length === 0
  },

  closeCargoConfirm ({ commit, state, rootState, dispatch }, payload) {
    commit('Shared/setLoadingMessage', 'Finalizando carga...', { root: true })
    let cargo = JSON.parse(JSON.stringify(state.cargo))
    const docID = `${cargo.branch_id}${cargo.cargo_id}`
    let userId = rootState.User.profile.id
    let companyId = rootState.User.profile.company_id
    let kmTruck = parseFloat(payload.km_truck) || false
    let cargoRef = db
      .collection('companies')
      .doc(companyId)
      .collection('cargos')
      .doc(docID)

    let validateForm = new Promise((resolve, reject) => {
      if (!companyId) reject(new Error('Company ID cant be empty'))
      if (!userId) reject(new Error('User ID cant be empty'))
      if (!kmTruck) reject(new Error('km cant be empty'))
      if (!cargo) reject(new Error('cargo cant be empty'))
      resolve()
    })

    // Dados para update no banco
    let cargoUpdate = {
      cargo_status: 'F',
      finished_by: userId,
      finished_at: timestamp,
      finished_km: kmTruck,
      finished_geo: null
    }

    // Dados para update do state
    cargo.cargo_status = 'F'
    cargo.finished_by = userId
    cargo.finished_at = { seconds: Math.round(new Date().getTime() / 1000) }
    cargo.finished_km = kmTruck
    cargo.finished_geo = null

    const metadata = {
      cargo_id: cargo.cargo_id,
      collection_name: 'cargos',
      doc_id: cargo.cargo_id,
      doc_field: `finished_km_photo_url`,
      image_tag: 'finished_km_photo',
      doc_ref: cargoRef
    }

    return validateForm
      .then(() => {
        commit('Shared/setLoadingMessage', 'Finalizando carga', { root: true })
        return getLocation()
      })
      .then(location => {
        cargoUpdate.finished_geo = location
        cargo.finished_geo = location

        // Update Truck KM
        dispatch('Trucks/saveAndSetTruckNewKm', kmTruck, { root: true })

        // Log
        logging(`Doc cargos/${docID} finished`, 'Cargos', 'Close Cargo')

        // Update cargo infos
        cargoRef.update(cargoUpdate) // .then(() => console.log(`Cargo ${docID} successfully finished`))

        // Upload photo and update database url
        if (payload.km_photo) {
          fileUpload.upload(payload.km_photo, metadata).then(base64Docid => {
            cargoRef.update({
              finished_km_photo: base64Docid
            })
          })
        }

        commit('changeCargos', cargo)
        commit('Shared/setLoadingMessage', '', { root: true })
        return { error: false, cargos: cargo }
      })
      .catch(err => {
        commit('Shared/setError', err.message, { root: true })
        return { error: err, cargos: null }
      })
  }
}
