import t from 'typy'
import { fbapp, db, auth } from '@/config/firebase'
import router from '@/router'
import { logging } from '@/services/logging.js'
import { getDefaultCompanyState } from '@/store/modules/user/state.js'

export default {
  userLogin ({ commit, dispatch, rootState }, payload) {
    // Validações
    let firstTests = 1
    if (!t(payload, 'form.password').isDefined && !t(payload, 'form.username').isDefined) firstTests = -1
    if (typeof payload.form.username !== 'string' && typeof payload.form.password !== 'string') firstTests = -2

    // Preparando variaveis e removendo whitespaces
    payload.form.username = payload.form.username.replace(/\s+/g, '').toLowerCase() // \s+ matches any whitespace character (equal to [\r\n\t\f\v ])
    payload.form.password = payload.form.password.replace(/^\s+|\s+$/g, '') // matches any whitespace no ínicio e no final apenas

    let form = {
      username: `${payload.form.username}@${rootState.User.companie.id}.app.minharota.com.br`,
      password: payload.form.password
    }

    const extralog = {
      form_companie: payload.form.companie,
      form_username: payload.form.username,
      company_id: t(rootState, 'User.companie.id').safeObject || null,
      login: form.username
    }

    // Regex Testes
    let formUsernameRegex = /^[a-z0-9._]+$/i
    let fullloginRegex = /^([a-z0-9._]+)@([a-z0-9]+).app.minharota.com.br$/i

    if (!formUsernameRegex.test(payload.form.username)) firstTests = -3
    if (!fullloginRegex.test(form.username)) firstTests = -4

    if (firstTests <= 0) {
      logging(`Usuário ou senha inválido enviados para login ${firstTests}`, 'Users', 'Login error', extralog)
      commit('Shared/setError', `Usuário ou senha inválido enviados para login (${firstTests})`, { root: true })
      return false
    }

    commit('Shared/setLoadingMessage', 'Autenticando usuário...', { root: true })
    return fbapp.auth()
      .setPersistence(auth.Persistence.LOCAL)
      .then(() => {
        // Try authenticate with login and password
        return fbapp.auth().signInWithEmailAndPassword(form.username, form.password)
      })

      .then(async userSignOp => {
        let customClaims = await fbapp.auth().currentUser.getIdTokenResult()
          .then(idTokenResult => idTokenResult.claims)

        if (customClaims.roles.includes('driver')) {
          return userSignOp
        } else {
          logging(`User ${form.username} without permissions to login`, 'Users', 'Login error')
          throw new Error('Usuário sem permissão de acesso')
        }
      })
      .then(async userSignOp => {
        // Get User profile from Firestore Database
        return dispatch('getUserProfile', userSignOp.user)
      })
      .then(userData => {
        if (userData.profile) {
          logging(`User logged in`, 'Users', 'Login', { fullDeviceReport: true })
          commit('Shared/setLoadingMessage', 'Usuário autenticado com sucesso', { root: true })
          setTimeout(() => { commit('Shared/setLoadingMessage', '', { root: true }) }, 1000)
        }
        return userData
      })
      .catch(async err => {
        dispatch('userLogout', { bypassRouter: true, bypassLog: true })

        extralog.error_code = err.code
        extralog.error_message = err.message

        if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
          logging(`Invalid user or password`, 'Users', 'Login error', extralog)
          commit('Shared/setError', 'Usuário ou senha inválido', { root: true })
        } else {
          logging(`Generic Error`, 'Users', 'Login error', extralog)
          commit('Shared/setError', err.message, { root: true })
        }

        return false
      })
  },

  async userLogout ({ rootState, commit }, payload = {}) {
    let bypassRouter = payload.bypassRouter || false
    let bypassLog = payload.bypassLog || false
    const redirectTo = (rootState.User.companie.dnsname) ? `/${rootState.User.companie.dnsname}` : ''

    if (!bypassLog) {
      const extralog = {
        login: t(rootState, 'User.profile.login').safeObject || null,
        company_id: t(rootState, 'User.companie.id').safeObject || null
      }
      logging(`User logged out`, 'Users', 'Logout', extralog, JSON.parse(JSON.stringify(rootState)))
    }

    // Reset all states
    Object.keys(rootState).forEach(stateModulesNames => {
      if (stateModulesNames !== 'route') commit(`${stateModulesNames}/resetState`, null, { root: true })
    })

    await fbapp.auth().signOut()
    if (!bypassRouter) router.push(`/login${redirectTo}`)
  },

  // Accept payload as object or just string
  // Receive {dnsname: this.form.companie, bypass: true} or just dnsname
  getCompanieByDnsName ({ commit }, payload) {
    if (payload) {
      let dnsname = payload.dnsname || payload
      let bypassRemoveMsg = payload.bypassRemoveMsg || false
      let bypassAddMsg = payload.bypassAddMsg || false

      if (!bypassAddMsg) commit('Shared/setLoadingMessage', 'Autenticando empresa...', { root: true })

      // Prepare dnsName string
      dnsname = (typeof (dnsname) === 'string') ? dnsname.toLowerCase().trim().replace(/ /g, '') : false

      if (dnsname) {
        return db.collection('companies_public')
          .where('dns_name', '==', dnsname).limit(1).get()
          .then(querySnapshot => {
            if (querySnapshot.empty) {
              return getDefaultCompanyState()
            } else {
              // Get returned value without foreach method
              const companie = querySnapshot.docs[querySnapshot.docs.length - 1].data()
              const companieId = querySnapshot.docs[querySnapshot.docs.length - 1].id

              // Only basic data before auth
              let companieData = getDefaultCompanyState()
              return Object.assign(companieData, { id: companieId, name: companie.public_name, dnsname: dnsname })
            }
          })
          .then(companieResult => {
            if (!companieResult.id) return false
            commit('changeCompanie', companieResult)
            if (!bypassRemoveMsg) commit('Shared/setLoadingMessage', '', { root: true })
            return companieResult
          })
      }
    }
  },

  // Get user and companie data from firestore
  // Receive a firebase user auth object or just uid as string
  async getUserProfile ({ rootState, commit }, payload = false) {
    let uid = false
    let token = null
    let lastloggedin = null

    // É um objeto recebido como retorno do fbapp.auth().signInWithEmailAndPassword(username, password)
    // ou retorno do fbapp.auth().currentUser()
    if (typeof payload === 'object' && payload.uid) {
      uid = payload.uid || null
      token = payload.refreshToken || null
      lastloggedin = t(payload, 'metadata.lastSignInTime').safeObject || null

    // Se payload vazio foi enviado, tento recuperar do state do usuário
    // utilizado para recarregar/atualizar o profile do usuário/compania do banco
    } else if (payload === false) {
      uid = t(rootState, 'User.profile.auth_uid').safeObject || null
      token = t(rootState, 'User.token').safeObject || null
      lastloggedin = t(rootState, 'User.lastloggedin').safeObject || null

    // considero que foi enviado um uid especifico para a consulta
    } else if (typeof payload === 'string') {
      uid = payload
    }

    if (uid && t(rootState, 'User.companie.id').isDefined) {
      let userProfile = null
      let companieProfile = getDefaultCompanyState()
      let myPromises = []

      // get user profile data
      myPromises[0] = db.collection('companies').doc(rootState.User.companie.id).collection('users')
        .where('auth_uid', '==', uid).limit(1).get()
        .then(async querySnapshot => {
          if (!querySnapshot.empty) {
            userProfile = querySnapshot.docs[querySnapshot.docs.length - 1].data()
            userProfile.id = querySnapshot.docs[querySnapshot.docs.length - 1].id
            userProfile.uid = querySnapshot.docs[querySnapshot.docs.length - 1].id
            if (typeof payload === 'object' && payload.uid) {
              userProfile.claims = await (payload.getIdTokenResult()).claims
            }
          }
        })

      // get companie profile data
      myPromises[1] = db.collection('companies')
        .doc(rootState.User.companie.id).get()
        .then(doc => {
          companieProfile = Object.assign(companieProfile, doc.data())
          companieProfile.id = doc.id
        })

      return Promise.all(myPromises)
        .then(promisesReturns => {
          let userData = {
            profile: userProfile,
            companie: companieProfile,
            token,
            lastloggedin
          }
          commit('changeUser', userData)
          return userData
        })
        .catch(err => {
          throw new Error(err)
        })
    } else {
      throw new Error('Invalid UID')
    }
  },

  // Obtem lista de ajudantes
  async getDriverHelpers({ commit, rootState }) {
    let users = db
      .collection('companies')
      .doc(rootState.User.companie.id)
      .collection('users')
      .orderBy('name')
      .where('branch_id', '==', rootState.User.profile.branch_id)
      .where('roles', 'array-contains', 'driverhelper')
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          return []
        } else {
          const data = []
          querySnapshot.forEach(doc => {
            data.push(doc.data())
          })
          return data
        }
      })
      .then(result => {
        commit('changeDriverHelpers', result)
        return result
      })
    return users
  },

  // Obtem lista de conferentes
  async getCheckers({ commit, rootState }) {
    let users = await db
      .collection('companies')
      .doc(rootState.User.companie.id)
      .collection('users')
      .orderBy('name')
      .where('branch_id', '==', rootState.User.profile.branch_id)
      .where('roles', 'array-contains', 'checker')
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          return []
        } else {
          const data = []
          querySnapshot.forEach(doc => {
            data.push(doc.data())
          })
          return data
        }
      })
      .then(result => {
        commit('changeCheckers', result)
        return result
      })
    return users
  }
}
